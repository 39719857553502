<template>
  <div>
    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาเปิดเเทง
      </h5>
      <flat-pickr
        v-model="startDate"
        :disabled="huayData.status === 3"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาปิดเเทง
      </h5>
      <flat-pickr
        v-model="endDate"
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <div
      v-if="!isClose"
      class="text-right pt-2 pb-2"
    >
      เหลือเวลาอีก : {{ getHourElapse() }}
    </div>
    <div class="text-right">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="setupNewRound"
      >
        <span>เเก้ไขเวลารอบ</span>
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BButton, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    flatPickr,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    huayData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    startDate: '',
    endDate: '',
    endTime: null,
    isClose: false,
  }),
  mounted() {
    this.startDate = this.huayData.roundStartDate
    this.endDate = this.huayData.roundEndDate
    this.endTime = new Date(this.huayData.endTime * 1000)
  },
  methods: {
    getHourElapse() {
      if (this.endTime == null) {
        return ''
      }
      const difference = this.endTime.getTime() - this.$store.state.app.serverTime

      if (difference < 0) {
        this.isClose = true
        // this.$forceUpdate();
        return 'ปิดรับเเทง'
      }

      let seconds = Math.floor(difference / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)

      hours %= 24
      minutes %= 60
      seconds %= 60

      return (
        `${this.pad2(hours)}:${this.pad2(minutes)}:${this.pad2(seconds)}`
      )
    },
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    ToDateString(_d, time) {
      return (
        `${this.pad2(_d.getDate())
        }/${
          this.pad2(_d.getMonth() + 1)
        }/${
          _d.getFullYear()
        } ${
          time}`
      )
    },
    setupNewRound() {
      // eslint-disable-next-line no-unreachable
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const res = await lottoService.getAuthen(`huay/round-update/id/${id}?startDate=${this.startDate}&endDate=${this.endDate}`)
            if (res.status === 200) {
              this.$emit('onSaveFinish')

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
