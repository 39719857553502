<template>
  <div>
    <b-row class="justify-content-between align-items-end">
      <b-col
        :style="xxx"
        cols="12"
        :md="huayData.type === 1?'7':'12'"
      >
        <b-card
          :class="huayData.type === 1?'mb-2 mb-md-0':'mb-2'"
          border-variant="lightGrey"
        >
          <validation-observer
            v-if="huayData.type === 1"
            ref="simpleRules"
          >
            <b-form @submit.prevent>
              <b-form-group
                label="รางวัลที่ 1"
                label-for="รางวัลที่ 1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThree"
                  rules="required|digits:6"
                >
                  <b-form-input
                    v-model="huayData.topThree"
                    placeholder="รางวัลที่ 1"
                    minlength="6"
                    maxlength="6"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สามตัวหน้าลำดับที่ 1"
                label-for="สามตัวหน้าลำดับที่ 1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThreeFront1"
                  rules="required|digits:3"
                >
                  <b-form-input
                    v-model="huayData.topThreeFront1"
                    placeholder="สามตัวหน้าลำดับที่ 1"
                    minlength="3"
                    maxlength="3"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สามตัวหน้าลำดับที่ 2"
                label-for="สามตัวหน้าลำดับที่ 2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThreeFront2"
                  rules="required|digits:3"
                >
                  <b-form-input
                    v-model="huayData.topThreeFront2"
                    placeholder="สามตัวหน้าลำดับที่ 2"
                    minlength="3"
                    maxlength="3"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สามตัวหลังลำดับที่ 1"
                label-for="สามตัวหลังลำดับที่ 1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThreeBack1"
                  rules="required|digits:3"
                >
                  <b-form-input
                    v-model="huayData.topThreeBack1"
                    placeholder="สามตัวหลังลำดับที่ 1"
                    minlength="3"
                    maxlength="3"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สามตัวหลังลำดับที่ 2"
                label-for="สามตัวหลังลำดับที่ 2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThreeBack2"
                  rules="required|digits:3"
                >
                  <b-form-input
                    v-model="huayData.topThreeBack2"
                    placeholder="สามตัวหลังลำดับที่ 2"
                    minlength="3"
                    maxlength="3"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สองตัว"
                label-for="สองตัว"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bottomTwo"
                  rules="required|digits:2"
                >
                  <b-form-input
                    v-model="huayData.bottomTwo"
                    placeholder="สองตัว"
                    minlength="2"
                    maxlength="2"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <validation-observer
            v-else-if="huayData.type === 2 || huayData.type === 3 || huayData.type === 4 || huayData.type === 6"
            ref="simpleRules"
          >
            <b-form

              @submit.prevent
            >
              <b-form-group
                label="สามตัวบน"
                label-for="สามตัวบน"
              >
                <validation-provider
                  #default="{ errors }"
                  name="topThree"
                  rules="required|digits:3"
                >
                  <b-form-input
                    v-model="huayData.topThree"
                    placeholder="สามตัวบน"
                    minlength="3"
                    maxlength="3"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="สองตัวล่าง"
                label-for="สองตัวล่าง"
              >
                <validation-provider
                  #default="{ errors }"
                  name="bottomTwo"
                  rules="required|digits:2"
                >
                  <b-form-input
                    v-model="huayData.bottomTwo"
                    placeholder="สองตัวล่าง"
                    minlength="2"
                    maxlength="2"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <validation-observer
            v-else-if="huayData.type === 5"
            ref="simpleRules"
          >
            <b-form

              @submit.prevent
            >
              <b-form-group
                label="เลขชุด 4 ตัว"
                label-for="เลขชุด 4 ตัว"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fourNumber"
                  rules="required|digits:4"
                >
                  <b-form-input
                    v-model="huayData.fourNumber"
                    placeholder="เลขชุด 4 ตัว"
                    minlength="4"
                    maxlength="4"
                    :disabled="isDisbleResultForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        :md="huayData.type === 1?'5':'12'"
        :class="huayData.type === 1?'mt-0':''"
      >
        <div v-if="huayData.status >= 5 && huayData.status <= 6">
          <b-list-group v-if="huayData.type !== 5">
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>3 ตัวบน</span>
              {{ toNumber(calculatedResult.topThree.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>3 ตัวโต้ด</span>
              {{ toNumber(calculatedResult.topThreeJump.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              v-if="huayData.type === 1"
              class="d-flex justify-content-between align-items-center"
            >
              <span>3 ตัวหน้า</span>
              {{ toNumber(calculatedResult.topThreeFront.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              v-if="huayData.type === 1"
              class="d-flex justify-content-between align-items-center"
            >
              <span>3 ตัวท้าย</span>
              {{ toNumber(calculatedResult.topThreeBack.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>2 ตัวบน</span>
              {{ toNumber(calculatedResult.topTwo.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>2 ตัวล่าง</span>
              {{ toNumber(calculatedResult.bottomTwo.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>วิ่งบน</span>
              {{ toNumber(calculatedResult.topRun.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>วิ่งล่าง</span>
              {{ toNumber(calculatedResult.bottomRun.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              variant="success"
            >
              <span>ยอดเดิมพันเครดิตทั้งหมด</span>
              {{ toNumber(calculatedResult.sumRollingAmount.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              variant="danger"
            >
              <span>ยอดจ่ายเครดิตทั้งหมด</span>
              {{ toNumber(calculatedResult.sumWinAmount.toFixed(2)) }}
            </b-list-group-item>
          </b-list-group>
          <b-list-group v-if="huayData.type === 5">
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>4 ตัวตรง</span>
              {{ toNumber(calculatedResult.fourNumber.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>4 ตัวโต๊ด</span>
              {{ toNumber(calculatedResult.fourNumberJump.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>3 ตัวตรง</span>
              {{ toNumber(calculatedResult.topThree.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>3 ตัวโต๊ด</span>
              {{ toNumber(calculatedResult.topThreeJump.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>2 ตัวหน้า</span>
              {{ toNumber(calculatedResult.topTwo.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>2 ตัวหลัง</span>
              {{ toNumber(calculatedResult.bottomTwo.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              variant="success"
            >
              <span>ยอดเดิมพันเครดิตทั้งหมด</span>
              {{ toNumber(calculatedResult.sumRollingAmount.toFixed(2)) }}
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              variant="danger"
            >
              <span>ยอดจ่ายเครดิตทั้งหมด</span>
              {{ toNumber(calculatedResult.sumWinAmount.toFixed(2)) }}
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>
    <div class="text-right">
      <template v-if="huayData.status === 1">
        <b-button
          variant="dark"
          size="13px"
          style="margin-right:5px"
          class="mt-2"
          @click="refund"
        >คืนโพย
        </b-button>
        <b-button
          variant="danger"
          size="13px"
          class="mt-2"
          @click="pause"
        >หยุดพักชั่วคราว
        </b-button>
      </template>
      <template v-else-if="huayData.status === 2">
        <b-button
          variant="dark"
          size="13px"
          style="margin-right:5px"
          class="mt-2"
          @click="refund"
        >คืนโพย
        </b-button>
        <b-button
          variant="primary"
          size="13px"
          class="mt-2"
          @click="resume"
        >เปิดเเทงต่อ
        </b-button>
      </template>
      <template v-else-if="huayData.status === 3">
        <b-button
          variant="dark"
          size="13px"
          style="margin-right:5px"
          class="mt-2"
          @click="refund"
        >คืนโพย
        </b-button>
        <b-button
          variant="secondary"
          size="13px"
          style="margin-right:5px"
          class="mt-2"
        >ดึงผล
        </b-button>
        <b-button
          variant="primary"
          size="13px"
          class="mt-2"
          @click="calculateCredit"
        >คำนวนราคา
        </b-button>
      </template>
      <template v-else-if="huayData.status === 5">
        <div>
          <b-button
            variant="primary"
            size="13px"
            style="margin-right:5px"
            class="mt-2"
            @click="calculateCredit"
          >คำนวนอีกครั้ง
          </b-button>
          <b-button
            variant="cyan-7"
            size="13px"
            class="mt-2"
            @click="showAnnounce"
          >ประกาศรางวัล
          </b-button>
        </div>
        <div>
          <b-button
            variant="dark"
            size="13px"
            style="margin-right:5px"
            class="mt-2"
            @click="refund"
          >คืนโพย
          </b-button>
          <b-button
            variant="secondary"
            size="13px"
            class="mt-2"
          >ดึงผล
          </b-button>
        </div>
      </template>
      <template v-else-if="huayData.status === 6">
        <div>
          <b-button
            variant="dark"
            size="13px"
            style="margin-right:5px"
            class="mt-2"
            @click="refund"
          >คืนโพย
          </b-button>
          <b-button
            variant="danger"
            size="13px"
            style="margin-right:5px"
            class="mt-2"
            @click="cancelAnnounce"
          >ยกเลิกประกาศ
          </b-button>
          <b-button
            variant="cyan-7"
            size="13px"
            class="mt-2"
            @click="dispatchCredit"
          >จ่ายเครดิต
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import lottoService from '@/service/lottoService'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BListGroup, BListGroupItem, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  props: {
    huayData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    result: {
      huayId: '',
      roundId: '',
      roundDate: '',
      roundStartDate: '',
      roundEndDate: '',
      lastUpdate: '',
      status: '',
      topThree: '',
      topThreeFront1: '',
      topThreeFront2: '',
      topThreeBack1: '',
      topThreeBack2: '',
      topTwo: '',
      bottomTwo: '',
      fourNumber: '',
    },
    calculatedResult: {
      topThree: 0,
      topThreeJump: 0,
      topThreeFront: 0,
      topThreeBack: 0,
      topTwo: 0,
      bottomTwo: 0,
      topRun: 0,
      bottomRun: 0,
      fourNumber: 0,
      fourNumberJump: 0,
      sumRollingAmount: 0,
      sumWinAmount: 0,
    },
  }),
  computed: {
    xxx() {
      return this.huayData.status < 5 || this.huayData.status > 6
        ? 'min-width:100% !important'
        : ''
    },
    isDisbleResultForm() {
      return this.huayData.status !== 3 && this.huayData.status !== 5
    },
    toNumber() {
      return this.$store.state.app.toNumber
    },
  },
  watch: {
    huayData: {
      async handler(val) {
        if (val.status === 5 || val.status === 6) {
          const res = await lottoService.getAuthen(`huay/calculate-histrory/${this.huayData.id}`)
          if (res.status === 200) this.calculatedResult = res.data
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.huayData.status === 5 || this.huayData.status === 6) {
      const res = await lottoService.getAuthen(`huay/calculate-histrory/${this.huayData.id}`)
      if (res.status === 200) this.calculatedResult = res.data
    }
  },
  methods: {
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    ToDateString(_d, time) {
      return (
        `${this.pad2(_d.getDate())
        }/${
          this.pad2(_d.getMonth() + 1)
        }/${
          _d.getFullYear()
        } ${
          time}`
      )
    },
    pause() {
      // หยุดพักชั่วคราว
      this.dispatchEvent('pause')
    },
    resume() {
      // เปิดเเทงต่อ
      this.dispatchEvent('resume')
    },
    refund() {
      // คืนเงิน
      this.dispatchEvent('refund')
      // this.dispatchSocketEvent("RefundHuay");
    },
    async calculateCredit() {
      // คำนวนราคา
      const {
        topThree,
        topThreeFront1,
        topThreeFront2,
        topThreeBack1,
        topThreeBack2,
        topTwo,
        bottomTwo,
        fourNumber,
      } = this.huayData

      const success = await this.$refs.simpleRules.validate()
      if (!success) {
        this.$swal({
          dark: true,
          title: 'Warning !',
          text: 'กรุณากรอกรางวัลให้ถูกต้อง',
          icon: 'warning',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }
      if (
        (topThree === '' || topThree == null)
          && (topThreeFront1 === '' || topThreeFront1 == null)
          && (topThreeFront2 === '' || topThreeFront2 == null)
          && (topThreeBack1 === '' || topThreeBack1 == null)
          && (topThreeBack2 === '' || topThreeBack2 == null)
          && (topTwo === '' || topTwo == null)
          && (bottomTwo === '' || bottomTwo == null)
          && (fourNumber === '' || fourNumber == null)
      ) {
        this.$swal({
          dark: true,
          title: 'Warning !',
          text: 'คุณยังไม่ได้กรอกผลรางวัล',
          icon: 'warning',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        return
      }

      this.dispatchEvent('calculate', '', {
        topThree,
        topThreeFront1,
        topThreeFront2,
        topThreeBack1,
        topThreeBack2,
        topTwo,
        bottomTwo,
        fourNumber,
      })

      // this.dispatchSocketEvent("CalculateCredit", {
      //   topThree,
      //   topThreeFront1,
      //   topThreeFront2,
      //   topThreeBack1,
      //   topThreeBack2,
      //   topTwo,
      //   bottomTwo,
      //   fourNumber
      // });
    },
    showAnnounce() {
      this.dispatchEvent('annouce', '?announce=true', {}, e => {
        if (e.status === 200) {
          this.calculatedResult = e.data.summary
        }
      })
    },
    cancelAnnounce() {
      this.dispatchEvent('annouce', '?announce=false', {}, e => {
        if (e.status === 200) {
          this.calculatedResult = e.data.summary
        }
      })
    },
    dispatchCredit() {
      this.dispatchEvent('dispatch')
      // this.dispatchSocketEvent("DispatchHuayReward");
    },
    dispatchEvent(event, param, arg, callback) {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
          .then(async result => {
            if (result.value) {
              const res = await lottoService.putAuthen(`huay/${event}/id/${id}${param || ''}`, arg || {})
              if (res.status === 200) {
                this.huayData.status = res.data.status
                if (callback) {
                  callback(res)
                }
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: res.data,
                  },
                })
              }
            }
          })
      }
    },
    // dispatchSocketEvent(event, arg) {
    //   const { id } = this.$route.query
    //   if (id) {
    //     this.$q
    //       .dialog({
    //         title: 'Confirm',
    //         message: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่',
    //         cancel: true,
    //         dark: this.dark,
    //       })
    //       .onOk(async () => {
    //         if (this.connection) {
    //           // eslint-disable-next-line no-shadow
    //           const { id } = this.$route.query
    //           if (arg) {
    //             this.connection
    //               .invoke(event, id * 1, arg)
    //               .catch(err => console.error(err.toString()))
    //           } else {
    //             this.connection.invoke(event, id * 1)
    //               .catch(err => console.error(err.toString()))
    //           }
    //         }
    //       })
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.border-lightGrey {
  border: 1px solid rgba(34, 41, 47, 0.125)
}

.dark-layout {
  .border-lightGrey {
    border-color: #3b4253;;
  }
}
</style>
