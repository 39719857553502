<template>
  <b-card
    v-if="!loaded"
    style="max-width: 600px;"
  >
    <b-card-text>
      <b-row
        class="justify-content-start align-items-center text-center text-sm-left"
        no-gutters
      >
        <b-col
          sm="auto"
        >
          <span
            class="mr-1"
            style="font-size: 1.5rem"
          >กำหนดรอบ {{ huayData.name }}</span>
        </b-col>
        <b-col
          sm="auto"
          class="mt-50 mt-sm-0 text-center"
        >
          <b-button
            style="width: 200px;"
            pill
            :variant="statusTextColor[status]"
          >
            {{ statusText[status] }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-form-group
          label="ล็อคผล"
          label-for="ล็อคผล"
        >
          <validation-provider
            #default="{ errors }"
            name=" "
            rules="required|digits:6"
          >
            <b-form-input
              v-model="huayData.target"
              placeholder="กรอกผลของยี่กีรอบนี้ที่ต้องการจะให้ออก"
              minlength="6"
              maxlength="6"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <div class="text-right mt-50">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="dark"
        size="13px"
        class="mr-50"
        @click="removeTarget"
      >ลบ</b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        size="13px"
        @click="saveTarget"
      >บันทึก</b-button>
    </div>

    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาเปิดเเทง
      </h5>
      <flat-pickr
        v-model="startDate"
        disabled
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาปิดเเทง
      </h5>
      <flat-pickr
        v-model="endDate"
        disabled
        class="form-control"
        :config="{ enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <b-list-group
      v-if="summary && summary.numberResult.length === 5"
      class="mt-2"
    >
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        เลขที่ออก [{{ summary.numberResult }}]
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สามตัวบน</span>
        {{ toNumber((summary.numberResult[0]+summary.numberResult[1]*1).toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สองตัวล่าง</span>
        {{ toNumber((summary.numberResult[2]+summary.numberResult[3]+summary.numberResult[4]).toFixed(2)) }}
      </b-list-group-item>
    </b-list-group>

    <b-list-group
      v-if="summary"
      class="mt-2"
    >
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        ผลการคำนวนเครดิต
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สามตัวบน</span>
        {{ toNumber(summary.topThree.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สามตัวโต๊ด</span>
        {{ toNumber(summary.topThreeJump.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สองตัวบน</span>
        {{ toNumber(summary.topTwo.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>สองตัวล่าง</span>
        {{ toNumber(summary.bottomTwo.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>วิ่งบน</span>
        {{ toNumber(summary.topRun.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        <span>วิ่งล่าง</span>
        {{ toNumber(summary.bottomRun.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
        variant="success"
      >
        <span>ยอดเดิมพันเครดิตทั้งหมด</span>
        {{ toNumber(summary.sumRollingAmount.toFixed(2)) }}
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-items-center"
        variant="danger"
      >
        <span>ยอดจ่ายเครดิตทั้งหมด</span>
        {{ toNumber(summary.sumWinAmount.toFixed(2)) }}
      </b-list-group-item>
    </b-list-group>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="dark"
      class="mt-2 mb-50 mr-50"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        class="mr-25"
      />
      <span>ย้อนกลับ</span>
    </b-button>
  </b-card>
</template>

<script>
/* eslint-disable */
import CreateRound from '@/views/components/CreateRound.vue'
import UpdateRoundTime from '@/views/lotto/UpdateRoundTime.vue'
import SettingResult from '@/views/components/SettingResult.vue'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormInput, BRow ,BListGroup , BListGroupItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CreateRound,
    UpdateRoundTime,
    SettingResult,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
    BListGroup ,
    BListGroupItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: true,
      statusText: [
        'ยังไม่ถึงเวลาเเทง',
        'อยู่ระหว่างเปิดให้เเทง',
        'หยุดพักชั่วคราว',
        'หมดเวลาเเทง',
        'อยู่ระหว่างคำนวนผล',
        'อยู่ระหว่างคำนวนเครดิต',
        'คำนวนเครดิตเรียบร้อยเเล้ว',
        'รอจ่ายเครดิต',
        'อยู่ระหว่างจ่ายเครดิต',
        'อยู่ระหว่างคืนโพย',
        'ปิดรอบเเทงเรียบร้อยเเล้ว',
      ],
      statusTextColor: [
        'cyan-7',
        'teal',
        'danger',
        'warning',
        'secondary',
        'secondary',
        'dark',
        'warning',
        'secondary',
        'secondary',
        'info-2',
      ],
      huayData: {
        name: '',
        externalLink: '',
        raw: '',
      },
      endTime: null,
      interval: null,
      startDate: '',
      endDate: '',
      isClose: false,
      summary: null,
    }
  },
  computed: {
    toNumber() {
      return this.$store.state.app.toNumber
    },
    status() {
      return this.huayData.status
    },
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval)
  },
  async mounted() {
    this.reload()
    this.setupHub()
    const res = await lottoService.getAuthen('huay/servertime')
    if (res.status === 200) {
      this.$store.state.serverTime = new Date(
          res.data.serverTime * 1000,
      ).getTime()

      this.interval = setInterval(() => {
        const oldTime = new Date(this.$store.state.serverTime)
        oldTime.setSeconds(oldTime.getSeconds() + 1)
        this.$store.state.serverTime = oldTime.getTime()

        if (this.huayData.status === 1) {
          const difference = this.endTime.getTime() - this.$store.state.serverTime
          if (difference < 0) {
            this.reload()
          }
        }
      }, 1000)
    }
  },
  methods: {
    getHourElapse() {
      if (this.endTime == null) {
        return ''
      }
      const difference = this.endTime.getTime() - this.$store.state.serverTime

      if (difference < 0) {
        this.isClose = true
        // this.$forceUpdate();
        return 'ปิดรับเเทง'
      }

      let seconds = Math.floor(difference / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)

      hours %= 24
      minutes %= 60
      seconds %= 60

      return (
          `${this.pad2(hours)}:${this.pad2(minutes)}:${this.pad2(seconds)}`
      )
    },
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    async removeTarget() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(async result => {
            if (result.value) {
              const res = await lottoService.putAuthen(
                  `yeekee/setting/target/${this.huayData.id}`, { target: '' },
              )
              if (res.status === 200) {
                this.reload()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ลบข้อมูลสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                  },
                })
              }
            }
          })
    },
    async saveTarget() {
      if (
          this.huayData.target === ''
          // eslint-disable-next-line no-restricted-globals
          || isNaN(this.huayData.target)
          || this.huayData.target.length < 6
          || this.huayData.target.length > 6
      ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'กรุณากรอกข้อมูลให้ถูกต้อง',
          },
        })
        return
      }
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(async result => {
            if (result.value) {
              const res = await lottoService.putAuthen(
                  `yeekee/setting/target/${this.huayData.id}`,
                  { target: `${this.huayData.target}` },
              )

              if (res.status === 200) {
                this.reload()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูลสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                  },
                })
              }
            }
          })
    },
    pause() {
      // หยุดพักชั่วคราว
      this.dispatchEvent('pause')
    },
    resume() {
      // เปิดเเทงต่อ
      this.dispatchEvent('resume')
    },
    refund() {
      // คืนเงิน
      this.dispatchEvent('refund')
    },
    async setupHub() {
      // this.$store.state.huayConnection = new signalR.HubConnectionBuilder()
      //   .withUrl(`${this.$store.state.huayHubUrl}/messages`)
      //   .build()
      //
      // await this.$store.state.huayConnection.start().then(() => {})
      //
      // this.$store.state.huayConnection.on(
      //   'onStateChange',
      //   async nextState => {
      //     if (nextState) {
      //       if (
      //         this.huayData.id === nextState.huayId
      //             && this.huayData.roundId === nextState.roundId
      //       ) {
      //         this.huayData.status = nextState.status
      //         if (nextState.isCreated) {
      //           this.reload()
      //         }
      //       }
      //     }
      //   },
      // )
    },
    async reload() {
      this.loaded = true
      const { id } = this.$route.query
      if (id) {
        const res = await lottoService.getAuthen(`yeekee/id/${id}`)
        setInterval(() => {
          // eslint-disable-next-line no-shadow
          lottoService.getAuthen('yeekee/states')
              .then(res => {
                if (res.status === 200) {
                  this.huayData.status = res.data[id]
                }
              })
        }, 3000)
        setTimeout(() => {
          if (res.status === 200) {
            const { data } = res
            this.huayData = data
            this.startDate = this.huayData.roundStartDate
            this.endDate = this.huayData.roundEndDate
            this.endTime = new Date(this.huayData.endTime * 1000)
            if (
                data.status === 6
                || data.status === 7
                || data.status === 8
                || data.status === 10
            ) {
              lottoService
                  .getAuthen(`yeekee/summary?id=${data.id}&round=${data.roundId}`)
                  // eslint-disable-next-line no-shadow
                  .then(res => {
                    if (res.status === 200) {
                      this.summary = res.data
                    }
                  })
            }
          }
          this.loaded = false
        }, 100)
      }
    },
    async save() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(async result => {
              if (result.value) {
                const res = await lottoService.putAuthen(`huay/id/${id}`, this.huayData)
                if (res.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'success',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'บันทึกข้อมูลสำเร็จ',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: res.data,
                    },
                  })
                }
              }
            })
      }
    },
    dispatchEvent(event, param, arg, callback) {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(async result => {
              if (result.value) {
                const res = await lottoService.putAuthen(`yeekee/${event}/id/${id}${param || ''}`, arg || {})
                if (res.status === 200) {
                  this.huayData.status = res.data.status
                  if (callback) {
                    callback(res)
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'success',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'ดำเนินการสำเร็จ',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: res.data,
                    },
                  })
                }
              }
            })
      }
    },
    finishIn1Min() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(async result => {
            if (result.value) {
              const res = await lottoService.getAuthen(`yeekee/test?id=${this.huayData.index}`)
              if (res.status === 200) {
                this.reload()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                  },
                })
              }
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
