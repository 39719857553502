<template>
  <div>
    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาเปิดเเทง
      </h5>
      <flat-pickr
        v-model="startDate"
        class="form-control"
        :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <b-form-group>
      <h5 class="font-weight-bold">
        วันเวลาปิดเเทง
      </h5>
      <flat-pickr
        v-model="endDate"
        class="form-control"
        :config="{ disableMobile:true,enableTime: true,dateFormat: 'd/m/Y H:i:S' , enableSeconds:true , time_24hr:true}"
      />
    </b-form-group>

    <div class="text-right pt-2 pb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="dark"
        class="mr-50"
        @click="useDefualt"
      >
        <span>ใช้เวลาเริ่มต้น</span>
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="setupNewRound"
      >
        <span>กำหนดรอบใหม่</span>
      </b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BButton, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

const d2 = new Date()
export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    flatPickr,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    huayData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    startDate: '',
    endDate: '',
  }),
  mounted() {
    this.startDate = this.ToDateString(d2, '00:00:00')
    this.endDate = this.ToDateString(d2, '23:59:59')
  },
  methods: {
    useDefualt() {
      this.startDate = this.ToDateString(d2, `${this.huayData.start}:00`)
      this.endDate = this.ToDateString(d2, `${this.huayData.end}:00`)
    },
    pad2(number) {
      return (number < 10 ? '0' : '') + number
    },
    ToDateString(_d, time) {
      return (
        `${this.pad2(_d.getDate())
        }/${
          this.pad2(_d.getMonth() + 1)
        }/${
          _d.getFullYear()
        } ${
          time}`
      )
    },
    setupNewRound() {
      // eslint-disable-next-line no-unreachable
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const res = await lottoService.getAuthen(`huay/round/id/${id}?startDate=${this.startDate}&endDate=${this.endDate}`)
            if (res.status === 200) {
              this.$emit('onSaveFinish')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
